<template>
     <div>
        <a-page-header
            class="header_title"
            :title="title"
            @back="() => $router.go(-1)"
        />
        <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" @submit="handleSubmit">
            <a-form-item :label="$t('product.add.lable_1')">
            <a-input
                v-decorator="[
                    'title',
                    { rules: [{ required: true, message: 'Please input!' }] },
                ]"
            />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_2')">
            <a-input
                style="width:220px;"
                suffix='USD'
                v-decorator="[
                    'cost',
                    { rules: [{ required: false, message: 'Please input!' }] },
                ]"
            />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_3')">
            <a-input
                style="width:220px;"
                v-decorator="[
                    'unit',
                    { rules: [{ required: true, message: 'Please input!' }] },
                ]"
            />
            </a-form-item>

            <a-form-item :label="$t('product.add.lable_4')">
                <a-input
                 style="width:220px;"
                 suffix='Lbs'
                 v-decorator="['weight_kg', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
             </a-form-item>
            <a-form-item :label="$t('product.add.lable_5')">
                <a-input 
                style="width:220px;"
                suffix='Inches' 
                v-decorator="['length_cm', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
            </a-form-item>

            <a-form-item :label="$t('product.add.lable_6')">
                <a-input 
                style="width:220px;"
                suffix='Inches' 
                v-decorator="['width_cm', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
            </a-form-item>

            <a-form-item :label="$t('product.add.lable_7')">
                <a-input 
                style="width:220px;"
                suffix='Inches' 
                v-decorator="['height_cm', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
            </a-form-item>

            <!-- <a-form-item :label="$t('product.add.lable_4_2')">
                <a-input
                 style="width:220px;"
                 suffix='kg'
                 v-decorator="['pack_weight_kg', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
             </a-form-item> -->
            <a-form-item :label="$t('product.add.lable_5_2')">
                <a-input 
                style="width:220px;"
                suffix='Inches' 
                v-decorator="['pack_length_cm', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
            </a-form-item>

            <a-form-item :label="$t('product.add.lable_6_2')">
                <a-input 
                style="width:220px;"
                suffix='Inches' 
                v-decorator="['pack_width_cm', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
            </a-form-item>

            <a-form-item :label="$t('product.add.lable_7_2')">
                <a-input 
                style="width:220px;"
                suffix='Inches' 
                v-decorator="['pack_height_cm', { rules: [{ required: false, message: 'Please input your note!' }] }]"/>
            </a-form-item>

            <!-- <a-form-item label="SKU">
            <a-input
                placeholder=""
                v-decorator="[
                    'sku',
                    { rules: [{ required: false, message: 'Please input!' }] },
                ]"
            />
            </a-form-item> -->
            <a-form-item :label="$t('product.add.lable_8')" style="margin-bottom:0;">
                <a-upload
                    action="https://labs.datahunt.app/img/uploadPic.php"
                    list-type="picture-card"
                    :file-list="fileList"
                    :data="{maker_id:'1'}"
                    @preview="handlePreview"
                    @change="handleChange"
                    :multiple="false"
                    @reject="rejectImgs"
                    :before-upload="beforeUpload"
                    >
                    <div v-if="fileList.length < 12">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                        Upload
                        </div>
                    </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_9')" style="margin-bottom:0;">
                    <Editor  
                         id="tinymce" v-model="tinymceHtml" :init="editorInit"></Editor>
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_10')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_1',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_11')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_2',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_12')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_3',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_13')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_4',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_14')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_5',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_15')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_6',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_16')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'selling_point_7',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>
            <a-form-item :label="$t('product.add.lable_17')" style="margin-bottom:0;">
                <a-textarea
                    placeholder=""
                    v-decorator="[
                        'simple_description',
                        { rules: [{ required: false, message: 'Please input!' }] },
                    ]"
                />
            </a-form-item>

            <a-form-item :label="$t('product.add.lable_18')" style="margin-bottom:0;">
                <a-select
                    v-decorator="[
                    'browse_product',
                    { rules: [{ required: true, message: $t('form.select_info') }] },
                    ]"
                    :placeholder="$t('form.select_info')"
                >
                    <a-select-option value="1">
                        {{$t('form.select_yes')}}
                    </a-select-option>
                    <a-select-option value="0">
                        {{$t('form.select_no')}}
                    </a-select-option>

                </a-select>
            </a-form-item>
            
            <a-form-item :label="$t('product.add.lable_19')" style="margin-bottom:0;">
                <a-select
                    v-decorator="[
                    'condition_status',
                    { rules: [{ required: true, message: $t('form.select_info') }] },
                    ]"
                    :placeholder="$t('form.select_info')"
                >
                    <a-select-option value="1">
                        {{$t('inventory.search.pre_owned')}}
                    </a-select-option>
                    <a-select-option value="2">
                        {{$t('inventory.search.used')}}
                    </a-select-option>
                    <a-select-option value="3">
                        {{$t('inventory.search.brand_new')}}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item align="center" :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit">
                    {{$t('product.add.btn_submit')}}
                </a-button>
            </a-form-item>

        </a-form>
    </div>
</template>

<script>
import {
  add,
  edit,
  getDetail,
} from "@/network/products2";

import Editor from '@tinymce/tinymce-vue';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.inline.min.css'
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import 'tinymce/icons/default';

export default {
    name: 'NodeprojectProducts',
    components: { Editor },
    directives: {  },
    data() {
        return {
            title:this.$t('form.add'),
            formLayout: 'horizontal',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            tinymceHtml:"",
            previewVisible: false,
            previewImage: '',
            fileList: [],
            imgs:[],
            editorInit: {
                height: 300, 
                plugins:"link lists image code table colorpicker textcolor wordcount contextmenu",
                content_css:'/public/skins/content/default/content.css',
                skin_url: '/public/skins/ui/oxide',
            }
        };
    },
    created(){
        if(this.$route.query.product_id){
            this.title = this.$t('form.edit');
            getDetail(this.$route.query.product_id)
            .then(res=>{
                if(res.code==0){
                let product=res.data;
                // console.log(product)
                this.form.setFieldsValue({
                    title:product.title,
                    unit:product.unit,
                    weight_kg:product.weight_kg,
                    length_cm:product.length_cm,
                    width_cm:product.width_cm,
                    height_cm:product.height_cm,
                    pack_length_cm:product.pack_length_cm,
                    pack_width_cm:product.pack_width_cm,
                    pack_height_cm:product.pack_height_cm,
                    cost:product.cost,
                    
                    selling_point_1:product.selling_point_1,
                    selling_point_2:product.selling_point_2,
                    selling_point_3:product.selling_point_3,
                    selling_point_4:product.selling_point_4,
                    selling_point_5:product.selling_point_5,
                    selling_point_6:product.selling_point_6,
                    selling_point_7:product.selling_point_7,
                    simple_description:product.simple_description,

                    condition_status:product.condition_status,
                    browse_product:product.browse_product,
                    //sku:product.sku,
                });
                this.tinymceHtml=product.description;
                if(product.img){
                    this.fileList=product.img.map((item,index)=>{ 
                        return  {
                            uid: index,
                            name: 'image.png',
                            status: 'done',
                            url: item.img,
                            }
                    });
                }
                return ;
            }
            });
        }
    },
    mounted() {
        tinymce.init({});
    },
    computed:{
     
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                let data = values;
                if (!err) {
                    let imgs=this.fileList.map((item,index)=>{
                        return {
                            priority:index,
                            img:item.url||item.response.body.url
                        }
                    });
                    data.description=this.tinymceHtml;
                    data.imgs=imgs;
                    console.log(data);
                    if(this.$route.query.product_id){
                        data.product_id=this.$route.query.product_id
                        //编辑更新产品by product_id
                        edit(data)
                        .then(res=>{
                            if(res.code==0){
                                this.$message.success("success!");
                                this.$router.back(-1);
                                return ;
                            }
                            this.$message.success(res.msg)
                        })
                        return ;
                    }
                    //添加产品add
                    add(data)
                    .then(res=>{
                        if(res.code==0){
                        this.$message.success("success!");
                        this.$router.back(-1)
                            return ;
                        }
                        this.$message.success(res.msg);
                    })

                }
            });
        },
        handleCancel() {
      this.previewVisible = false;
    },
    //放大图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
        // console.log(info)
        this.fileList=info.fileList 
        //  if(info.file.status==="uploading"){
        //   this.fileList=info.fileList 
        // }
        // if(info.file.status==="removed"){
        //     this.fileList=info.fileList 
        // }
        // if(info.file.status==='done'){
           
        // }
    },
    beforeUpload(file) {
        var that = this;
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const bigLt2M = file.size / 1024 / 1024 < 2;
        return new Promise((resolve, reject) => {
            if (!(isJPG || isJPEG || isPNG)) {
            that.$message.error('You can only upload JPG file!');
            reject(file);
            }else if (!bigLt2M) {
            that.$message.error('Image must smaller than 2MB!');
            reject(file);
            }else {
            resolve(file && this.checkImageWH2(file, 1200, 1200));
            }
      });
    },
    checkImageWH2(file, width, height) {
      var that = this;
      return new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            // 获取图片的宽高，并存放到file对象中
            console.log('file width :' + this.width);
            console.log('file height :' + this.height);
            file.width = this.width;
            file.height = this.height;
            resolve();
            // if (this.width == width && this.height == height)
            // {
            //   resolve();
            // } else {
            //   that.$message.error('Please upload '+width+'px*'+height+'px image');
            //   reject();
            // }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    rejectImgs(fileList){
        // console.log(fileList)
    },
    handleSizeChange(value) {
      console.log(`Selected: ${value}`);
    },

    },
};
</script>

<style  scoped>


</style>